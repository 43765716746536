import React from 'react'
import InfiniteLooper from '../../components/InfiniteLooper'

const FifthSection = () => {

  return (
    <div className='mt-12 container mx-auto pb-12 border-b-2 border-blue-secondary'>
      <p data-aos="fade-right" className="text-left text-blue-primary font-bold text-3xl">
        CONVENZIONI
      </p>

      <div className='my-5'>
        <InfiniteLooper speed="15" direction="left">
          <img
            src="/images/conventions/1.jpg"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/2.png"
            className='mr-10 w-[100px] sm:w-[60px]'
          />
          <img
            src="/images/conventions/3.png"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/4.png"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/5.png"
            className='mr-10 w-[100px] sm:w-[90px]'
          />
          <img
            src="/images/conventions/6.webp"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/7.png"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/8.jpg"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/9.jpg"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/10.jpg"
            className='mr-10 w-[100px] sm:w-[120px]'
          />
          <img
            src="/images/conventions/11.jpg"
            className='mr-10 w-[100px] sm:w-[60px]'
          />
        </InfiniteLooper>
      </div>

    </div>
  )
}

export default FifthSection